
import { Card,Button,List, Input } from 'antd'
import { InjectedConnector } from '@web3-react/injected-connector'
import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from 'react'
import { aggregate } from '@makerdao/multicall'
const injected = new InjectedConnector({
  supportedChainIds: [56,97],
})

const config = {
  preset: 'mainnet',
  rpcUrl: 'https://endpoints.omniatech.io/v1/bsc/mainnet/public',
  multicallAddress: '0xff6fd90a470aaa0c1b8a54681746b07acdfedc9b',
  target: "0x990B08EF7CD0C26FfAeFE6C7efd09fD5a619DCEF", //代理合约
  mainPair:"0xf4775cbF702C8b401b1703F87DeCa5B09F9a47f6",//lp地址
  nft:"0x2E5F00B0D518063Fb3293Fb4f972E8b64FdC2bf1",//nft地址
};

const Main = () => {
  const { active, account, library, connector, activate, deactivate } = useWeb3React();
  const [data,setData] = useState([]);
  const [target,setTarget] = useState(config.target);
  const [nft,setNFT] = useState(config.nft);
  const [mainPair,setMainPair] = useState(config.mainPair);

  const updateData = async (target,mainToken,account,nft) => {
      let multicalls = [
        {
          target: target,
          call: ['getNFTRewardPool(address)(uint)',account],
          returns: [["nftRewardPool"]]
        },
        {
          target: target,
          call: ['power(address)(uint,uint,uint,uint,uint,uint,uint,uint,uint,uint)',account],
          returns: [["buyer"],["seller"],["allBuyer"],["allSeller"],["rewardsRepos"],["bonusRepos"],["businessPoolRepos"],["userPoolRepos"],["sharePoolRepos"],["nftPoolRepos"]]
        },
        {
          target: target,
          call: ['_LockLpDates(address,address)(uint)',mainPair,account],
          returns: [["lockLpDate"]]
        },
        {
          target: target,
          call: ['_lockLpsInterval()(uint)'],
          returns: [["lockLpsInterval"]]
        },
        {
          target: target,
          call: ['_openLpsInterval()(uint)'],
          returns: [["openLpsInterval"]]
        },
        {
          target: target,
          call: ['sellLockInterval()(uint)'],
          returns: [["sellLockInterval"]]
        },
        {
          target: nft,
          call: ['balanceOf(address)(uint)',account],
          returns: [["nftAmount"]]
        },
        {
          target: mainPair,
          call: ['token1()(address)'],
          returns: [["token"]]
        }
      ];
    
      let results = await aggregate(multicalls,config);
      let _data = results.results.transformed;

      multicalls = [
        {
          target: _data.token,
          call: ['symbol()(string)'],
          returns: [["tokenSymbol"]]
        }
      ];
      results = await aggregate(multicalls,config);
      _data.tokenSymbol = results.results.transformed.tokenSymbol;
      let nowTime = new Date().getTime();
      let allInterval = parseInt(Number(_data.openLpsInterval)+Number(_data.lockLpsInterval));
      let currentInterval = (new Date().getTime()/1000 - Number(_data.lockLpDate))%allInterval;
      let isOpen = currentInterval>_data.lockLpsInterval&&_data.lockLpsInterval<_data.openLpsInterval+_data.lockLpsInterval;
      let nextTime = new Date(nowTime + (allInterval - currentInterval)*1000);
      let nextOpen = new Date(nextTime.getTime()-Number(_data.openLpsInterval)*1000);
      let temp = [
        "[All hashrate of buyer] : "+ library.utils.fromWei(_data.allBuyer+"")+" (point)",
        "[All hashrate of seller] : "+ library.utils.fromWei(_data.allSeller+"")+" (point)",
        "[Hashrate of your's seller] : "+library.utils.fromWei(_data.seller+"")+" (point)",
        "[Hashrate of your's buyer] : "+library.utils.fromWei(_data.buyer+"")+" (point)",
        "[Current open state] : " + (isOpen?"opened":"closed"),
        "[Next LP opening time] : "+nextOpen.toLocaleString()+"-->"+nextTime.toLocaleString(),
        "[RewardPool of NFT] : "+library.utils.fromWei(_data.nftRewardPool+"")+" (coins)",
        "[Amount of NFT] : "+_data.nftAmount,
        "[Amount of reward pool] : "+library.utils.fromWei(_data.rewardsRepos+"")+" ("+_data.tokenSymbol +")",
        "[Amount of bonus pool] : "+library.utils.fromWei(_data.bonusRepos+"")+" ("+_data.tokenSymbol +")",
        "[Amount of buyers pool] : "+library.utils.fromWei(_data.userPoolRepos+"")+" ("+_data.tokenSymbol +")",
        "[Amount of sellers pool] : "+library.utils.fromWei(_data.businessPoolRepos+"")+" ("+_data.tokenSymbol +")",
        "[Amount of shares pool] : "+library.utils.fromWei(_data.sharePoolRepos+"")+" ("+_data.tokenSymbol +")",
        "[Amount of nft reward pool] : "+library.utils.fromWei(_data.nftPoolRepos+"")+" ("+_data.tokenSymbol +")",
      ];
      console.log(results);
      setData(temp);
    
  }
  const onTargetChange = (e)=>{
      // console.log(e.target.value);
      setTarget(e.target.value);
  }
  const onMainPairChange = (e)=>{
      setMainPair(e.target.value);
  }
  const onNFTChange = (e)=>{
      setNFT(e.target.value);
  }
  const onRefresh = async (e)=>{
    if(account){
      await updateData(target,mainPair,account,nft);
    }
  }
  useEffect(() => {
    injected.isAuthorized().then(async (isAuthorized) => {
      await connect();
      if(account){
        await updateData(target,mainPair,account,nft);
      }
    })
  }, [activate,account]) 
  async function connect() {
    try {
      await activate(injected)
    } catch (ex) {
      console.log(ex)
    }
  }
  async function disconnect() {
    try {
      deactivate()
    } catch (ex) {
      console.log(ex)
    }
  }
    return (
      <div className="Main">
        {active?
        (<Card>
          <Button onClick={disconnect}>Disconnect</Button>
          {config.preset == "testnet"&&(
          <>
            <Input value={target} onChange={onTargetChange} placeholder="target" style={{marginTop:"20px"}}/>
            <Input value={mainPair} onChange={onMainPairChange} placeholder="mainPair" />
            <Input value={nft} onChange={onNFTChange} placeholder="nft"/>
            <Button onClick={onRefresh} style={{marginTop:"20px"}}>refresh</Button>
          </>
          )}
         
        </Card>):(<Card >
          <Button  onClick={connect} type="primary" >Connect to metamask</Button>
          
        </Card>)
        }
        
        <List
          size="large"
          header={account&&<h4 className=''>Connected with :{account}</h4>}
          
          // footer={<div>Footer</div>}
          bordered
          dataSource={data}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
        
      </div>
    )
  }

export default Main
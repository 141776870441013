import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IpfsRouter from 'ipfs-react-router'
import Main from './pages/Main';
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3/dist/web3.min.js'

function getLibrary(provider) {
  return new Web3(provider)
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
        <div className="App">
          <IpfsRouter>
            <Routes>
                <Route path="/" element={<Main/>}/>
            </Routes>
          </IpfsRouter>
        </div>
    </Web3ReactProvider>
      
  );
}

export default App;
